import localAgent from '@/plugins/local-agent.js'


class utilPrintHtml {


  constructor(fontSize) {
    this.fontSize = fontSize;
  }

  render(data) {
    let html = '';
    html += this.renderStyles();
    html += this.renderContent(data);
    html += this.renderFooter();
    return html;
  }

  getImageCodeBar(text, format = 'EAN13') {
    const canvas = document.createElement('canvas');
    // JsBarcode(canvas, text, {format: format});
    return canvas.toDataURL('image/png');
  }

  renderCodebar(producto, cantidad) {
    for (let i = 0; i < cantidad; i++) {
      this.html += `<div class="center" style="padding-bottom:5px; margin-bottom:10px; border-bottom:solid #000 1px; font-size:15px;">`;
      this.html += '' + producto.nombre + '<br>';
      this.html += '<img width="100%" src="' + this.getImageCodeBar(700000000013) + '">';
      this.html += `</div>`;
    }
  }

  renderContent(data) {
    let html = '';
    for (const value of data) {
      if (value.type == 'image') {
        html += this.renderImage(value);
      }
      if(value.type == 'imageBase64'){
        html += this.renderImageB64(value);
      }
      if (value.type == 'line') {
        html += this.renderLine();
      }
      if (value.type == 'table') {
        html += this.renderTable(value);
      }
      if (value.type == 'spacing') {
        html += this.renderSpacing();
      }
      if (value.type == 'text') {
        html += this.renderText(value);
      }
      if (value.type == 'total') {
        html += this.renderTotal(value);
      }
    }
    return html;
  }

  renderStyles() {
    let html = `
    <!doctype html>
            <html>
              <head>
                <meta charset="utf-8">
                <style type="text/css" media="all">
                  body{font-size:` + this.fontSize + `px font-family: arial, sans-serif;  }
                  .size-default{ font-size:` + this.fontSize + `px }
                  .size-print-text-footer{ font-size: 11px; padding: 3px,}
                  #body-factura { margin:0 auto; text-align:center; color:#000;  }
                  #wrapper-factura {  }
                  #wrapper-factura img { width: 100%; }
                  #wrapper-factura #logo img { width: 60%; }
                  #wrapper-factura h2, #wrapper-factura h3, #wrapper-factura p { margin: 5px 0; }
                  #wrapper-factura .center { width:100%; float:left; text-align:center; margin: 0 auto; }
                  #wrapper-factura .left-div { width:100%; float:left; text-align:left; margin: 0 auto; }
                  #wrapper-factura .full-l { width:100%; float:left; text-align:left; margin: 0 auto; font-size:14px; }
                  #wrapper-factura .left { width:55%; float:left; text-align:left; margin-bottom: 3px; }
                  #wrapper-factura .right { width:45%; float:right; text-align:right; margin-bottom: 3px; }
                  #wrapper-factura .table, .totals { width: 100%; margin:10px 0; }
                  #wrapper-factura .table th { }
                  #wrapper-factura .table td { padding:2px; }
                  #wrapper-factura .totals td { width: 24%; padding:0; }
                  #wrapper-factura .table td:nth-child(2) { overflow:hidden; }
                  #wrapper-factura .table .border-item { text-decoration:underline; }
                  #wrapper-factura .footer_text {  }
                  #wrapper-factura .table_total { display : table;clear : both;table-layout : fixed;width : 100%; }
                  #wrapper-factura .table_total .tr{ display : table-row; clear : both;empty-cells : show;width : 100%; }
                  #wrapper-factura .table_total .tr .td{ display : table-column;float : left;width : 30%; min-width : 30%;empty-cells : show;box-sizing: border-box;padding : 1px 1px 1px 1px; }
                  #wrapper-factura .table_total .tr .td.fix_value{ width : 70%; min-width : 70%; }                   
                  .square-check { width:3px; border: 2px solid #000; padding:5px; float:right;}
                  @media print {
                  @page { margin: 0cm 0cm 0cm 0cm; }
                      #buttons { display: none; }
                      #wrapper-factura { width: 100%; margin: 0; font-size:9px; }
                      #wrapper-factura img {  width: 85%; }
                  }
                </style>
              </head>
              <body id="body-factura">
                <div id="wrapper-factura">`;
    return html;
  }

  renderText(item) {
    let html = `<div class="${item.class}" style="font-size: ${item.font_size};text-align:${item.align};">`;
    html += `${item.value}`;
    html += `</div>`;
    return html;
  }
  renderLine() {
    let html = `<div class="size-default center" style="margin:5px; border-bottom:solid #000 1px;"></div>`;
    return html;
  }

  renderTotal(item) {
    let html = `<div class="table_total" style="font-size: ${item.font_size};margin:5px 0; padding:5px 0;">
        <div class="tr">
          <div class="td" style="text-align:left">
            ${item.label}
          </div>
          <div class="td fix_value" style="text-align:right">
            ${item.value}
          </div>
        </div>
      </div>`;
    return html;
  }

  renderTable(item) {
    let html = `<div class="table_row" style="font-size: ${item.font_size}px;margin: 0; padding:5px 0 10px;">`;
    if (item.rows) {
      for (const i in item.rows) {
        if (item.rows.hasOwnProperty(i)) {
          if (item.add_check) {
            html += `<div class="square-check"></div>`;
          }
          const last_item = item.rows[i][item.rows[i].length - 1];
          for (const key in item.rows[i]) {
            if (item.rows[i].hasOwnProperty(key)) {
              html += `<div style="font-size: ${item.font_size};text-align:left;">`;
              if (item.rows[i][key] == '') {
                // html += `<br/>`;
              } else {
                html += `${item.rows[i][key]}`;
              }
              html += `</div>`;

              if (item.add_check) {
                if (item.rows[i][key] == last_item) {
                  html += `<div class="size-default center" style="margin:10px 0; border-bottom:dashed #000 1px;"></div>`;
                }
              }

            }
          }
        }
      }
    }
    html += '</div>';
    return html;
  }

  renderSpacing() {
    let html = `<br>`;
    return html;
  }
  
  renderImage(item) {
    
    let html = '<div class="img_default ' + item.class + '" style="" >';    
    html += '<img rel="preload" loading="eager" decoding="sync" src="' + item.value + '" width="' + item .width+'" /><br>';          
    html += `</div>`;
    html += '<div style="clear:both;"></div>';
    return html;
  }

  renderImageB64(item) {   
    let format = '';
    if(item.format){
      if(item.format == 'jpg'){
        format= 'data:image/jpeg;base64,'
      }
      if(item.format == 'png'){
        format= 'data:image/png;base64,'
      }
    }
    let html = '<div class="img_default ' + item.class + '" style="" >';    
    html += '<img loading="eager" decoding="sync" src="' + format + ' ' + item.value + '" width="' + item .width+'" />';          
    html += `</div>`;
    html += '<div style="clear:both;"></div>';
    return html;
  }

  renderFooter() {
    let html = `
          <div id="buttons" style="padding-top:10px; text-transform:uppercase; text-align:center;">
            <button type="button" onClick="window.print(); return false;"
            style="width:100%; text-transform: uppercase;width:95%;
            cursor:pointer; font-size:12px; background-color:#FFA93C;
            color:#FFF; text-align: center; border:2px solid #FFA93C; padding: 10px 1px; font-weight:bold;">Imprimir
            </button>
            <div style="clear:both;"></div>
            <div style="background:#F5F5F5; margin-top:10px; padding:10px; text-align-justify;">
              <div style="font-weight:bold;">
              no olvide deshabilitar la cabecera y el pie de página en la sección imprimir de su navegador web.
              </div>
              <div style="text-transform: capitalize;">
                <strong>chrome:</strong>
                Menu Archivo > Imprimir > Deshabilitar Encabezado y pie de página en Configuración y Seleccionar Márgenes a Ninguno o Minimos.
                </div>
            </div>
            <div style="clear:both;"></div>
          </div>
        </div>
      </body>
      </html>`;
    return html;
  }

  print(html, modePrinter, type, printConfig) {

    // ipcMain
    return new Promise((resolve, reject) => {
      try {

        if (modePrinter == 'browser') {
          const newWin = window.frames['printf'];
          newWin.document.write('<pre>' + html + '</pre>');
          // solucion para que cargue las imagenes a veces no las renderizaba por que el iframe no las carga
          setTimeout(function(){
            newWin.print();
        }, 500);
          
          newWin.document.close();
          resolve();          
        } 

        if (modePrinter == 'agent') {
          let content = '' + html + '';
          localAgent.print(content).then((response) => {
            //console.log(response)
          })
        }


      } catch (error) {
        console.log('er', error);
        reject(error);
      }
    });
  }


  printElectron(html) {
    try {
      //ipcRenderer.send('service-printer-print', {html:html, options:this.options });
    } catch (err) {
      console.error(err);
    }
  }

}

export default utilPrintHtml;